import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from "styled-components";
import { scales } from "./types";

/**
 * Priority: Warning --> Success
 */
var getBoxShadow = function getBoxShadow(_ref) {
  var _ref$isSuccess = _ref.isSuccess,
      isSuccess = _ref$isSuccess === void 0 ? false : _ref$isSuccess,
      _ref$isWarning = _ref.isWarning,
      isWarning = _ref$isWarning === void 0 ? false : _ref$isWarning,
      theme = _ref.theme;

  if (isWarning) {
    return theme.shadows.warning;
  }

  if (isSuccess) {
    return theme.shadows.success;
  }

  return theme.shadows.inset;
};

var getHeight = function getHeight(_ref2) {
  var _ref2$scale = _ref2.scale,
      scale = _ref2$scale === void 0 ? scales.MD : _ref2$scale;

  switch (scale) {
    case scales.SM:
      return "32px";

    case scales.LG:
      return "48px";

    case scales.MD:
    default:
      return "40px";
  }
};

var Input = styled.input(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: ", ";\n  border-radius: 16px;\n  box-shadow: ", ";\n  color: ", ";\n  display: block;\n  font-size: 16px;\n  height: ", ";\n  outline: 0;\n  padding: 0 16px;\n  width: 100%;\n  border: 1px solid ", ";\n\n  &::placeholder {\n    color: ", ";\n  }\n\n  &:disabled {\n    background-color: ", ";\n    box-shadow: none;\n    color: ", ";\n    cursor: not-allowed;\n  }\n\n  &:focus:not(:disabled) {\n    box-shadow: ", ";\n  }\n"])), function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.input;
}, getBoxShadow, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.text;
}, getHeight, function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.inputSecondary;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.colors.textSubtle;
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.colors.backgroundDisabled;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.colors.textDisabled;
}, function (_ref9) {
  var theme = _ref9.theme,
      isWarning = _ref9.isWarning,
      isSuccess = _ref9.isSuccess;

  if (isWarning) {
    return theme.shadows.warning;
  }

  if (isSuccess) {
    return theme.shadows.success;
  }

  return theme.shadows.focus;
});
Input.defaultProps = {
  scale: scales.MD,
  isSuccess: false,
  isWarning: false
};
export default Input;