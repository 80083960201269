import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _variants, _templateObject2, _variants2;

import styled from "styled-components";
import { variant as StyledSystemVariant } from "styled-system";
import { variants } from "./types";
import TokenImage from "./TokenImage";
export var StyledPrimaryImage = styled(TokenImage)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: absolute;\n  width: ", "; // 92, 82 are arbitrary numbers to fit the variant\n\n  ", "\n"])), function (_ref) {
  var variant = _ref.variant;
  return variant === variants.DEFAULT ? "92%" : "82%";
}, StyledSystemVariant({
  variants: (_variants = {}, _defineProperty(_variants, variants.DEFAULT, {
    bottom: "auto",
    left: 0,
    right: "auto",
    top: 0,
    zIndex: 5
  }), _defineProperty(_variants, variants.INVERTED, {
    bottom: 0,
    left: "auto",
    right: 0,
    top: "auto",
    zIndex: 6
  }), _variants)
}));
export var StyledSecondaryImage = styled(TokenImage)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: absolute;\n  width: 50%;\n\n  ", "\n"])), StyledSystemVariant({
  variants: (_variants2 = {}, _defineProperty(_variants2, variants.DEFAULT, {
    bottom: 0,
    left: "auto",
    right: 0,
    top: "auto",
    zIndex: 6
  }), _defineProperty(_variants2, variants.INVERTED, {
    bottom: "auto",
    left: 0,
    right: "auto",
    top: 0,
    zIndex: 5
  }), _variants2)
}));