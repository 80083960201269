import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from "styled-components";
import { space } from "styled-system";
var CardFooter = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border-top: 1px solid ", ";\n  ", "\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.cardBorder;
}, space);
CardFooter.defaultProps = {
  p: "24px"
};
export default CardFooter;