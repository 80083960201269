import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from "styled-components";
import { color } from "styled-system";

var getBorderRadius = function getBorderRadius(_ref) {
  var scale = _ref.scale;
  return scale === "md" ? "16px 16px 0 0" : "24px 24px 0 0";
};

var getPadding = function getPadding(_ref2) {
  var scale = _ref2.scale;
  return scale === "md" ? "8px" : "16px";
};

var Tab = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: inline-flex;\n  justify-content: center;\n  cursor: pointer;\n  border: 0;\n  outline: 0;\n  flex-grow: 1;\n  padding: ", ";\n  border-radius: ", ";\n  font-size: 16px;\n  font-weight: 600;\n\n  ", " {\n    flex-grow: 0;\n  }\n\n  ", "\n"])), getPadding, getBorderRadius, function (_ref3) {
  var theme = _ref3.theme;
  return theme.mediaQueries.md;
}, color);
Tab.defaultProps = {
  scale: "md"
};
export default Tab;