import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import styled from "styled-components";
export var StyledMenuItemContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n\n  ", ";\n"])), function (_ref) {
  var $isActive = _ref.$isActive,
      $variant = _ref.$variant,
      theme = _ref.theme;
  return $isActive && $variant === "subMenu" && "\n      &:after{\n        content: \"\";\n        position: absolute;\n        bottom: 0;\n        height: 4px;\n        width: 100%;\n        background-color: ".concat(theme.colors.primary, ";\n        border-radius: 2px 2px 0 0;\n      }\n    ");
});
var StyledMenuItem = styled.a(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: relative;\n  display: flex;\n  align-items: center;\n\n  color: ", ";\n  font-size: 16px;\n  font-weight: ", ";\n\n  ", "\n\n  ", "\n\n  &:hover {\n    background: ", ";\n    ", ";\n  }\n"])), function (_ref2) {
  var theme = _ref2.theme,
      $isActive = _ref2.$isActive;
  return $isActive ? theme.colors.secondary : theme.colors.textSubtle;
}, function (_ref3) {
  var $isActive = _ref3.$isActive;
  return $isActive ? "600" : "400";
}, function (_ref4) {
  var $statusColor = _ref4.$statusColor,
      theme = _ref4.theme;
  return $statusColor && "\n    &:after {\n      content: \"\";\n      border-radius: 100%;\n      background: ".concat(theme.colors[$statusColor], ";\n      height: 8px;\n      width: 8px;\n      margin-left: 12px;\n    }\n  ");
}, function (_ref5) {
  var $variant = _ref5.$variant;
  return $variant === "default" ? "\n    padding: 0 16px;\n    height: 48px;\n  " : "\n    padding: 4px 4px 0px 4px;\n    height: 42px;\n  ";
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.colors.tertiary;
}, function (_ref7) {
  var $variant = _ref7.$variant;
  return $variant === "default" && "border-radius: 16px;";
});
export default StyledMenuItem;