import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _FACTORY_ADDRESS_MAP, _INIT_CODE_HASH_MAP, _SOLIDITY_TYPE_MAXIMA;

import JSBI from 'jsbi'; // exports for external consumption

export var ChainId;

(function (ChainId) {
  ChainId[ChainId["BSC"] = 11451] = "BSC";
  ChainId[ChainId["BSC_TESTNET"] = 97] = "BSC_TESTNET";
})(ChainId || (ChainId = {}));

export var TradeType;

(function (TradeType) {
  TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
  TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));

export var Rounding;

(function (Rounding) {
  Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
  Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
  Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));

export var FACTORY_ADDRESS = '0x353F34F703c072090390452424Bee67E8992C536';
export var FACTORY_ADDRESS_MAP = (_FACTORY_ADDRESS_MAP = {}, _defineProperty(_FACTORY_ADDRESS_MAP, ChainId.BSC, FACTORY_ADDRESS), _defineProperty(_FACTORY_ADDRESS_MAP, ChainId.BSC_TESTNET, '0x6725f303b657a9451d8ba641348b6761a6cc7a17'), _FACTORY_ADDRESS_MAP);
export var INIT_CODE_HASH = '0xb554915b49140e30b3b89dcba86bbfa31ca3d16da970f532bbf7e7b7e72ab6f0';
export var INIT_CODE_HASH_MAP = (_INIT_CODE_HASH_MAP = {}, _defineProperty(_INIT_CODE_HASH_MAP, ChainId.BSC, INIT_CODE_HASH), _defineProperty(_INIT_CODE_HASH_MAP, ChainId.BSC_TESTNET, '0xd0d4c4cd0848c93cb4fd1f498d7013ee6bfb25783ea21593d5834f5d250ece66'), _INIT_CODE_HASH_MAP);
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1000); // exports for internal consumption

export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export var FEES_NUMERATOR = JSBI.BigInt(9975);
export var FEES_DENOMINATOR = JSBI.BigInt(10000);
export var SolidityType;

(function (SolidityType) {
  SolidityType["uint8"] = "uint8";
  SolidityType["uint256"] = "uint256";
})(SolidityType || (SolidityType = {}));

export var SOLIDITY_TYPE_MAXIMA = (_SOLIDITY_TYPE_MAXIMA = {}, _defineProperty(_SOLIDITY_TYPE_MAXIMA, SolidityType.uint8, JSBI.BigInt('0xff')), _defineProperty(_SOLIDITY_TYPE_MAXIMA, SolidityType.uint256, JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')), _SOLIDITY_TYPE_MAXIMA);