import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _styleVariants, _styleScales;

import { variants, scales } from "./types";
export var styleVariants = (_styleVariants = {}, _defineProperty(_styleVariants, variants.ROUND, {
  borderRadius: "32px"
}), _defineProperty(_styleVariants, variants.FLAT, {
  borderRadius: 0
}), _styleVariants);
export var styleScales = (_styleScales = {}, _defineProperty(_styleScales, scales.MD, {
  height: "16px"
}), _defineProperty(_styleScales, scales.SM, {
  height: "8px"
}), _styleScales);
export default styleVariants;