import { useContext } from "react";
import { MatchBreakpointsContext } from "./Provider";

var useMatchBreakpointsContext = function useMatchBreakpointsContext() {
  var matchBreakpointContext = useContext(MatchBreakpointsContext);

  if (matchBreakpointContext === undefined) {
    throw new Error("Match Breakpoint context is undefined");
  }

  return matchBreakpointContext;
};

export default useMatchBreakpointsContext;