import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _scaleVariants, _styleVariants;

import { scales, variants } from "./types";
export var scaleVariants = (_scaleVariants = {}, _defineProperty(_scaleVariants, scales.MD, {
  height: "28px",
  padding: "0 8px",
  fontSize: "14px"
}), _defineProperty(_scaleVariants, scales.SM, {
  height: "24px",
  padding: "0 4px",
  fontSize: "12px"
}), _scaleVariants);
export var styleVariants = (_styleVariants = {}, _defineProperty(_styleVariants, variants.PRIMARY, {
  backgroundColor: "primary"
}), _defineProperty(_styleVariants, variants.SECONDARY, {
  backgroundColor: "secondary"
}), _defineProperty(_styleVariants, variants.SUCCESS, {
  backgroundColor: "success"
}), _defineProperty(_styleVariants, variants.TEXTDISABLED, {
  backgroundColor: "textDisabled"
}), _defineProperty(_styleVariants, variants.TEXTSUBTLE, {
  backgroundColor: "textSubtle"
}), _defineProperty(_styleVariants, variants.BINANCE, {
  backgroundColor: "binance"
}), _defineProperty(_styleVariants, variants.FAILURE, {
  backgroundColor: "failure"
}), _defineProperty(_styleVariants, variants.WARNING, {
  backgroundColor: "warning"
}), _styleVariants);