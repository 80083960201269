import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useCallback, useEffect } from "react";
import debounce from "lodash/debounce";
var defaultParticleOptions = {
  size: 30,
  distance: 500
};

var createParticle = function createParticle(x, y, imgSrc) {
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  var _defaultParticleOptio = _objectSpread(_objectSpread({}, defaultParticleOptions), options),
      size = _defaultParticleOptio.size,
      distance = _defaultParticleOptio.distance;

  var particle = document.createElement("particle");
  document.body.appendChild(particle);
  var width = Math.floor(Math.random() * size + 8);
  var height = width;
  var destinationX = (Math.random() - 0.5) * distance;
  var destinationY = (Math.random() - 0.5) * distance;
  var rotation = Math.random() * 520;
  var delay = Math.random() * 200;
  particle.style.backgroundRepeat = "no-repeat";
  particle.style.backgroundSize = "contain";
  particle.style.backgroundImage = "url(".concat(imgSrc, ")");
  particle.style.left = "0";
  particle.style.top = "0";
  particle.style.opacity = "0";
  particle.style.pointerEvents = "none";
  particle.style.position = "fixed";
  particle.style.width = "".concat(width, "px");
  particle.style.height = "".concat(height, "px");
  var animation = particle.animate([{
    transform: "translate(-50%, -50%) translate(".concat(x, "px, ").concat(y, "px) rotate(0deg)"),
    opacity: 1
  }, {
    transform: "translate(-50%, -50%) translate(".concat(x + destinationX, "px, ").concat(y + destinationY, "px) rotate(").concat(rotation, "deg)"),
    opacity: 0
  }], {
    duration: Math.random() * 1000 + 5000,
    easing: "cubic-bezier(0, .9, .57, 1)",
    delay: delay
  });

  animation.onfinish = function () {
    particle.remove();
  };
};

var defaultOptions = {
  numberOfParticles: 30,
  debounceDuration: 200,
  particleOptions: {}
};
/**
 * @see https://css-tricks.com/playing-with-particles-using-the-web-animations-api/
 */

var useParticleBurst = function useParticleBurst(options) {
  var _defaultOptions$optio = _objectSpread(_objectSpread({}, defaultOptions), options),
      selector = _defaultOptions$optio.selector,
      numberOfParticles = _defaultOptions$optio.numberOfParticles,
      debounceDuration = _defaultOptions$optio.debounceDuration,
      imgSrc = _defaultOptions$optio.imgSrc,
      disableWhen = _defaultOptions$optio.disableWhen,
      particleOptions = _defaultOptions$optio.particleOptions;

  var makeListener = useCallback(function () {
    return debounce(function (event) {
      var isDisabled = disableWhen && disableWhen();

      if (!isDisabled) {
        var node = event.currentTarget;

        if (event.clientX === 0 && event.clientY === 0) {
          var _node$getBoundingClie = node.getBoundingClientRect(),
              left = _node$getBoundingClie.left,
              width = _node$getBoundingClie.width,
              top = _node$getBoundingClie.top,
              height = _node$getBoundingClie.height;

          var x = left + width / 2;
          var y = top + height / 2;

          for (var i = 0; i < numberOfParticles; i += 1) {
            createParticle(x, y, imgSrc, particleOptions);
          }
        } else {
          for (var _i = 0; _i < numberOfParticles; _i += 1) {
            createParticle(event.clientX, event.clientY + window.scrollY, imgSrc, particleOptions);
          }
        }
      }
    }, debounceDuration, {
      leading: true
    });
  }, [debounceDuration, numberOfParticles, imgSrc, disableWhen, particleOptions]);
  var listener = makeListener();
  var initialize = useCallback(function () {
    if (selector) {
      document.querySelectorAll(selector).forEach(function (element) {
        element.addEventListener("click", listener);
      });
    } else {
      document.addEventListener("click", listener);
    }
  }, [selector, listener]);
  var teardown = useCallback(function () {
    if (selector) {
      document.querySelectorAll(selector).forEach(function (element) {
        element.removeEventListener("click", listener);
      });
    } else {
      document.removeEventListener("click", listener);
    }
  }, [selector, listener]);
  useEffect(function () {
    initialize();
    return function () {
      return teardown();
    };
  }, [initialize, teardown]);
  return {
    initialize: initialize,
    teardown: teardown
  };
};

export default useParticleBurst;