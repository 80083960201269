import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import { keyframes } from "styled-components";
export var appearAnimation = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  from { opacity:0 }\n  to { opacity:1 }\n"])));
export var disappearAnimation = keyframes(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  from { opacity:1 }\n  to { opacity:0 }\n"])));
export var animationHandler = function animationHandler(element) {
  if (!element) return;

  if (element.classList.contains("appear")) {
    element.classList.remove("appear");
    element.classList.add("disappear");
  } else {
    element.classList.remove("disappear");
    element.classList.add("appear");
  }
};
export var animationVariants = {
  initial: {
    transform: "translateX(0px)"
  },
  animate: {
    transform: "translateX(0px)"
  },
  exit: {
    transform: "translateX(0px)"
  }
};
export var animationMap = {
  initial: "initial",
  animate: "animate",
  exit: "exit"
};