var PATH = "https://cdn.pancakeswap.com/sequnce-assets/";
export var BNB2CAKE_PATH = "".concat(PATH, "bnb2cake/bnb2cake-");
export var BNB2CAKE_COUNTS = 31;
export var CAKE2BNB_PATH = "".concat(PATH, "cakebnb/cake2bnb-");
export var CAKE2BNB_COUNTS = 31;
export var FILE_TYPE = ".png";

var pathGenerator = function pathGenerator(path) {
  return function (d, index) {
    if (index < 10) return "".concat(path, "0").concat(index).concat(FILE_TYPE);
    return "".concat(path).concat(index).concat(FILE_TYPE);
  };
};

export var bnb2CakeImages = function bnb2CakeImages() {
  var result = new Array(BNB2CAKE_COUNTS);
  result.fill("");
  result = result.map(pathGenerator(BNB2CAKE_PATH));
  return result;
};
export var cake2BnbImages = function cake2BnbImages() {
  var result = new Array(CAKE2BNB_COUNTS);
  result.fill("");
  result = result.map(pathGenerator(CAKE2BNB_PATH));
  return result;
};