import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import styled from "styled-components";
import { Text } from "../Text";
export var StyledBottomNavItem = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: block;\n  border: 0;\n  background: transparent;\n  cursor: pointer;\n  height: 44px;\n  padding: 4px 12px;\n  &:hover {\n    border-radius: 16px;\n  }\n  &:hover,\n  &:hover div {\n    background: ", ";\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.tertiary;
});
export var StyledBottomNavText = styled(Text)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: -webkit-box;\n  overflow: hidden;\n  user-select: none;\n  -webkit-line-clamp: 1;\n  -webkit-box-orient: vertical;\n  -webkit-user-select: none;\n  -webkit-touch-callout: none;\n"])));