import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import styled, { keyframes, css } from "styled-components";
import { space } from "styled-system";
import { Box } from "../Box";
var PromotedGradient = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  0% {\n    background-position: 50% 0%;\n  }\n  50% {\n    background-position: 50% 100%;\n  }\n  100% {\n    background-position: 50% 0%;\n  }\n"])));

/**
 * Priority: Warning --> Success --> Active
 */
var getBorderColor = function getBorderColor(_ref) {
  var isActive = _ref.isActive,
      isSuccess = _ref.isSuccess,
      isWarning = _ref.isWarning,
      borderBackground = _ref.borderBackground,
      theme = _ref.theme;

  if (borderBackground) {
    return borderBackground;
  }

  if (isWarning) {
    return theme.colors.warning;
  }

  if (isSuccess) {
    return theme.colors.success;
  }

  if (isActive) {
    return "linear-gradient(180deg, ".concat(theme.colors.primaryBright, ", ").concat(theme.colors.secondary, ")");
  }

  return theme.colors.cardBorder;
};

export var StyledCard = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  background: ", ";\n  border-radius: ", ";\n  color: ", ";\n  overflow: hidden;\n  position: relative;\n\n  ", "\n\n  padding: 1px 1px 3px 1px;\n\n  ", "\n"])), getBorderColor, function (_ref2) {
  var theme = _ref2.theme;
  return theme.radii.card;
}, function (_ref3) {
  var theme = _ref3.theme,
      isDisabled = _ref3.isDisabled;
  return theme.colors[isDisabled ? "textDisabled" : "text"];
}, function (_ref4) {
  var isActive = _ref4.isActive;
  return isActive && css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      animation: ", " 3s ease infinite;\n      background-size: 400% 400%;\n    "])), PromotedGradient);
}, space);
export var StyledCardInner = styled(Box)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 100%;\n  overflow: ", ";\n  background: ", ";\n  border-radius: ", ";\n"])), function (_ref5) {
  var hasCustomBorder = _ref5.hasCustomBorder;
  return hasCustomBorder ? "initial" : "inherit";
}, function (_ref6) {
  var theme = _ref6.theme,
      background = _ref6.background;
  return background !== null && background !== void 0 ? background : theme.card.background;
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.radii.card;
});
StyledCard.defaultProps = {
  isActive: false,
  isSuccess: false,
  isWarning: false,
  isDisabled: false
};