import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from "styled-components";
import { space } from "styled-system";
var CardBody = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), space);
CardBody.defaultProps = {
  p: "24px"
};
export default CardBody;