import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import styled from "styled-components";
import { lightColors } from "../../theme";
export var TimelineContainer = styled.ul(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  list-style: none;\n"])));
export var TimelineEvent = styled.li(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  position: relative;\n  margin-bottom: 14px;\n\n  &:after {\n    content: \"\";\n    position: absolute;\n    left: 9px;\n    top: 26px;\n    width: 2px;\n    height: 10px;\n    background-color: ", ";\n  }\n\n  &:last-child:after {\n    display: none;\n  }\n"])), function (_ref) {
  var theme = _ref.theme,
      $useDark = _ref.$useDark;
  return $useDark ? theme.colors.textSubtle : lightColors.textSubtle;
});