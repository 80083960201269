import _toConsumableArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import { TelegramIcon, YoutubeIcon, DiscordIcon } from "../Svg";
export var footerLinks = [{
  label: "About",
  items: [{
    label: "Contact",
    href: ""
  }, {
    label: "Blog",
    href: ""
  }, {
    label: "Community",
    href: ""
  }, {
    label: "CAKE",
    href: "https://docs.cloudtx.finance/tokenomics/cake"
  }, {
    label: "—"
  }, {
    label: "Online Store",
    href: "https://pancakeswap.creator-spring.com/",
    isHighlighted: true
  }]
}, {
  label: "Help",
  items: [{
    label: "Customer",
    href: "Support https://docs.cloudtx.finance/contact-us/customer-support"
  }, {
    label: "Troubleshooting",
    href: "https://docs.cloudtx.finance/help/troubleshooting"
  }, {
    label: "Guides",
    href: "https://docs.cloudtx.finance/get-started"
  }]
}, {
  label: "Developers",
  items: [{
    label: "Github",
    href: ""
  }, {
    label: "Documentation",
    href: ""
  }, {
    label: "Bug Bounty",
    href: ""
  }, {
    label: "Audits",
    href: ""
  }, {
    label: "Careers",
    href: ""
  }]
}];
export var socials = [{
  label: "Telegram",
  icon: TelegramIcon,
  href: "https://t.me/defipower_official"
}, {
  label: "Telegram",
  icon: TelegramIcon,
  href: "https://t.me/egoldchain"
}, // {
//   label: "Reddit",
//   icon: RedditIcon,
//   href: "https://reddit.com/r/pancakeswap",
// },
// {
//   label: "Instagram",
//   icon: InstagramIcon,
//   href: "https://instagram.com/pancakeswap_official",
// },
// {
//   label: "Github",
//   icon: GithubIcon,
//   href: "https://github.com/cloudtxchain",
// },
{
  label: "Discord",
  icon: DiscordIcon,
  href: "https://discord.gg/Kg9wGKmk"
}, {
  label: "Youtube",
  icon: YoutubeIcon,
  href: "https://www.youtube.com/@Defipower_official"
}];
export var langs = _toConsumableArray(Array(20)).map(function (_, i) {
  return {
    code: "en".concat(i),
    language: "English".concat(i),
    locale: "Locale".concat(i)
  };
});