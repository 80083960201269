import _slicedToArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useMemo, useReducer, useEffect, useCallback } from "react";
import noop from "lodash/noop";
import { byTextAscending, byTextDescending } from "./utils";

var sortByColumn = function sortByColumn(data, sortColumn, columns) {
  var isAscending = null;

  var sortedRows = _toConsumableArray(data);

  columns.forEach(function (column) {
    // if the row was found
    if (sortColumn === column.name) {
      isAscending = column.sorted.asc;

      if (column.sort) {
        sortedRows = isAscending ? data.sort(column.sort) : data.sort(column.sort).reverse(); // default to sort by string
      } else {
        sortedRows = isAscending ? data.sort(byTextAscending(function (object) {
          return object.original[sortColumn];
        })) : data.sort(byTextDescending(function (object) {
          return object.original[sortColumn];
        }));
      }
    }
  });
  return sortedRows;
};

var getPaginatedData = function getPaginatedData(rows, perPage, page) {
  var start = (page - 1) * perPage;
  var end = start + perPage;
  return rows.slice(start, end);
};

var getColumnsByName = function getColumnsByName(columns) {
  var columnsByName = {};
  columns.forEach(function (column) {
    var col = {
      name: column.name,
      label: column.label
    };

    if (column.render) {
      col.render = column.render;
    }

    col.hidden = column.hidden;
    columnsByName[column.name] = col;
  });
  return columnsByName;
};

var createReducer = function createReducer() {
  return function (state, action) {
    var rows = [];
    var nextPage = 0;
    var prevPage = 0;
    var isAscending = null;
    var sortedRows = [];
    var columnCopy = [];
    var filteredRows = [];
    var selectedRowsById = {};

    var stateCopy = _objectSpread({}, state);

    var rowIds = {};

    switch (action.type) {
      case "SET_ROWS":
        rows = _toConsumableArray(action.data); // preserve sorting if a sort is already enabled when data changes

        if (state.sortColumn) {
          rows = sortByColumn(action.data, state.sortColumn, state.columns);
        }

        if (state.paginationEnabled === true) {
          rows = getPaginatedData(rows, state.pagination.perPage, state.pagination.page);
        }

        if (state.paginationEnabled === true) {
          rows = getPaginatedData(rows, state.pagination.perPage, state.pagination.page);
        }

        columnCopy = state.columns.map(function (column) {
          if (state.sortColumn === column.name) {
            return _objectSpread(_objectSpread({}, column), {}, {
              sorted: {
                on: true,
                asc: column.sorted.asc
              }
            });
          }

          return column;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          rows: rows,
          originalRows: action.data,
          columns: columnCopy
        });

      case "NEXT_PAGE":
        nextPage = state.pagination.page + 1;
        return _objectSpread(_objectSpread({}, state), {}, {
          rows: getPaginatedData(state.originalRows, state.pagination.perPage, nextPage),
          pagination: _objectSpread(_objectSpread({}, state.pagination), {}, {
            page: nextPage,
            canNext: nextPage * state.pagination.perPage < state.originalRows.length,
            canPrev: nextPage !== 1
          })
        });

      case "PREV_PAGE":
        prevPage = state.pagination.page === 1 ? 1 : state.pagination.page - 1;
        return _objectSpread(_objectSpread({}, state), {}, {
          rows: getPaginatedData(state.originalRows, state.pagination.perPage, prevPage),
          pagination: _objectSpread(_objectSpread({}, state.pagination), {}, {
            page: prevPage,
            canNext: prevPage * state.pagination.perPage < state.originalRows.length,
            canPrev: prevPage !== 1
          })
        });

      case "TOGGLE_SORT":
        if (!(action.columnName in state.columnsByName)) {
          throw new Error("Invalid column, ".concat(action.columnName, " not found"));
        } // loop through all columns and set the sort parameter to off unless
        // it's the specified column (only one column at a time for )


        columnCopy = state.columns.map(function (column) {
          // if the row was found
          if (action.columnName === column.name) {
            if (action.isAscOverride !== undefined) {
              // force the sort order
              isAscending = action.isAscOverride;
            } else {
              // if it's undefined, start by setting to ascending, otherwise toggle
              isAscending = column.sorted.asc === undefined ? true : !column.sorted.asc;
            }

            if (column.sort) {
              sortedRows = isAscending ? state.rows.sort(column.sort) : state.rows.sort(column.sort).reverse(); // default to sort by string
            } else {
              sortedRows = isAscending ? state.rows.sort(byTextAscending(function (object) {
                return object.original[action.columnName];
              })) : state.rows.sort(byTextDescending(function (object) {
                return object.original[action.columnName];
              }));
            }

            return _objectSpread(_objectSpread({}, column), {}, {
              sorted: {
                on: true,
                asc: isAscending
              }
            });
          } // set sorting to false for all other columns


          return _objectSpread(_objectSpread({}, column), {}, {
            sorted: {
              on: false,
              asc: false
            }
          });
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          columns: columnCopy,
          rows: sortedRows,
          sortColumn: action.columnName,
          columnsByName: getColumnsByName(columnCopy)
        });

      case "GLOBAL_FILTER":
        filteredRows = action.filter(state.originalRows);
        selectedRowsById = {};
        state.selectedRows.forEach(function (row) {
          var _row$selected;

          selectedRowsById[row.id] = (_row$selected = row.selected) !== null && _row$selected !== void 0 ? _row$selected : false;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          rows: filteredRows.map(function (row) {
            return selectedRowsById[row.id] ? _objectSpread(_objectSpread({}, row), {}, {
              selected: selectedRowsById[row.id]
            }) : _objectSpread({}, row);
          }),
          filterOn: true
        });

      case "SELECT_ROW":
        stateCopy = _objectSpread({}, state);
        stateCopy.rows = stateCopy.rows.map(function (row) {
          var newRow = _objectSpread({}, row);

          if (newRow.id === action.rowId) {
            newRow.selected = !newRow.selected;
          }

          return newRow;
        });
        stateCopy.originalRows = stateCopy.originalRows.map(function (row) {
          var newRow = _objectSpread({}, row);

          if (newRow.id === action.rowId) {
            newRow.selected = !newRow.selected;
          }

          return newRow;
        });
        stateCopy.selectedRows = stateCopy.originalRows.filter(function (row) {
          return row.selected === true;
        });
        stateCopy.toggleAllState = stateCopy.selectedRows.length === stateCopy.rows.length ? stateCopy.toggleAllState = true : stateCopy.toggleAllState = false;
        return stateCopy;

      case "SEARCH_STRING":
        stateCopy = _objectSpread({}, state);
        stateCopy.rows = stateCopy.originalRows.filter(function (row) {
          return row.cells.filter(function (cell) {
            if (cell.value.includes(action.searchString)) {
              return true;
            }

            return false;
          }).length > 0;
        });
        return stateCopy;

      case "TOGGLE_ALL":
        if (state.selectedRows.length < state.rows.length) {
          stateCopy.rows = stateCopy.rows.map(function (row) {
            rowIds[row.id] = true;
            return _objectSpread(_objectSpread({}, row), {}, {
              selected: true
            });
          });
          stateCopy.toggleAllState = true;
        } else {
          stateCopy.rows = stateCopy.rows.map(function (row) {
            rowIds[row.id] = false;
            return _objectSpread(_objectSpread({}, row), {}, {
              selected: false
            });
          });
          stateCopy.toggleAllState = false;
        }

        stateCopy.originalRows = stateCopy.originalRows.map(function (row) {
          return row.id in rowIds ? _objectSpread(_objectSpread({}, row), {}, {
            selected: rowIds[row.id]
          }) : _objectSpread({}, row);
        });
        stateCopy.selectedRows = stateCopy.originalRows.filter(function (row) {
          return row.selected;
        });
        return stateCopy;

      default:
        throw new Error("Invalid reducer action");
    }
  };
};

var sortDataInOrder = function sortDataInOrder(data, columns) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return data.map(function (row) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var newRow = {};
    columns.forEach(function (column) {
      if (!(column.name in row)) {
        throw new Error("Invalid row data, ".concat(column.name, " not found"));
      }

      newRow[column.name] = row[column.name];
    });
    return newRow;
  });
};

export var makeRender = function makeRender(value, render, row) {
  return render ? function () {
    return render({
      row: row,
      value: value
    });
  } : function () {
    return value;
  };
};

var makeHeaderRender = function makeHeaderRender(label, render) {
  return render ? function () {
    return render({
      label: label
    });
  } : function () {
    return label;
  };
};

export var useTable = function useTable(columns, data, options) {
  var columnsWithSorting = useMemo(function () {
    return columns.map(function (column) {
      return _objectSpread(_objectSpread({}, column), {}, {
        label: column.label ? column.label : column.name,
        hidden: column.hidden ? column.hidden : false,
        sort: column.sort,
        sorted: {
          on: false,
          asc: false
        }
      });
    });
  }, [columns]);
  var columnsByName = useMemo(function () {
    return getColumnsByName(columnsWithSorting);
  }, [columnsWithSorting]);
  var tableData = useMemo(function () {
    var sortedData = sortDataInOrder(data, columnsWithSorting);
    var newData = sortedData.map(function (row, idx) {
      return {
        id: idx,
        selected: false,
        hidden: false,
        original: row,
        cells: Object.entries(row).map(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
              column = _ref2[0],
              value = _ref2[1];

          return {
            hidden: columnsByName[column].hidden,
            field: column,
            value: value,
            render: makeRender(value, columnsByName[column].render, row)
          };
        }).filter(function (cell) {
          return !cell.hidden;
        })
      };
    });
    return newData;
  }, [data, columnsWithSorting, columnsByName]);
  var reducer = createReducer();

  var _useReducer = useReducer(reducer, {
    columns: columnsWithSorting,
    columnsByName: columnsByName,
    originalRows: tableData,
    rows: tableData,
    selectedRows: [],
    toggleAllState: false,
    filterOn: !!(options !== null && options !== void 0 && options.filter),
    sortColumn: options === null || options === void 0 ? void 0 : options.sortColumn,
    paginationEnabled: !!(options !== null && options !== void 0 && options.pagination),
    pagination: {
      page: 1,
      perPage: 10,
      canNext: true,
      canPrev: false,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      nextPage: noop,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      prevPage: noop
    }
  }),
      state = _useReducer[0],
      dispatch = _useReducer[1];

  state.pagination.nextPage = useCallback(function () {
    dispatch({
      type: "NEXT_PAGE"
    });
  }, [dispatch]);
  state.pagination.prevPage = useCallback(function () {
    return dispatch({
      type: "PREV_PAGE"
    });
  }, [dispatch]);
  useEffect(function () {
    dispatch({
      type: "SET_ROWS",
      data: tableData
    });
  }, [tableData]);
  var headers = useMemo(function () {
    return _toConsumableArray(state.columns.map(function (column) {
      var label = column.label ? column.label : column.name;
      return _objectSpread(_objectSpread({}, column), {}, {
        render: makeHeaderRender(label, column.headerRender)
      });
    }));
  }, [state.columns]);
  useEffect(function () {
    if (options && options.filter) {
      dispatch({
        type: "GLOBAL_FILTER",
        filter: options.filter
      });
    }
  });
  return {
    headers: headers.filter(function (column) {
      return !column.hidden;
    }),
    rows: state.rows,
    originalRows: state.originalRows,
    selectedRows: state.selectedRows,
    dispatch: dispatch,
    selectRow: function selectRow(rowId) {
      return dispatch({
        type: "SELECT_ROW",
        rowId: rowId
      });
    },
    toggleAll: function toggleAll() {
      return dispatch({
        type: "TOGGLE_ALL"
      });
    },
    toggleSort: function toggleSort(columnName, isAscOverride) {
      return dispatch({
        type: "TOGGLE_SORT",
        columnName: columnName,
        isAscOverride: isAscOverride
      });
    },
    setSearchString: function setSearchString(searchString) {
      return dispatch({
        type: "SEARCH_STRING",
        searchString: searchString
      });
    },
    pagination: state.pagination,
    toggleAllState: state.toggleAllState
  };
};