import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from "styled-components";
import { space, typography, layout } from "styled-system";
import getThemeValue from "../../util/getThemeValue";

var getColor = function getColor(_ref) {
  var color = _ref.color,
      theme = _ref.theme;
  return getThemeValue(theme, "colors.".concat(color), color);
};

var Text = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: ", ";\n  font-weight: ", ";\n  line-height: 1.5;\n  ", "\n  ", "\n\n  ", "\n  ", "\n  ", "\n\n  ", "\n"])), getColor, function (_ref2) {
  var bold = _ref2.bold;
  return bold ? 600 : 400;
}, function (_ref3) {
  var textTransform = _ref3.textTransform;
  return textTransform && "text-transform: ".concat(textTransform, ";");
}, function (_ref4) {
  var ellipsis = _ref4.ellipsis;
  return ellipsis && "white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;";
}, space, typography, layout, function (_ref5) {
  var small = _ref5.small;
  return small && "font-size: 14px;";
});
Text.defaultProps = {
  color: "text",
  small: false,
  fontSize: "16px",
  ellipsis: false
};
export default Text;