import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _style, _templateObject;

import styled from "styled-components";
import Text from "../Text/Text";
import { tags, scales } from "./types";
var style = (_style = {}, _defineProperty(_style, scales.MD, {
  fontSize: "20px",
  fontSizeLg: "20px"
}), _defineProperty(_style, scales.LG, {
  fontSize: "24px",
  fontSizeLg: "24px"
}), _defineProperty(_style, scales.XL, {
  fontSize: "32px",
  fontSizeLg: "40px"
}), _defineProperty(_style, scales.XXL, {
  fontSize: "48px",
  fontSizeLg: "64px"
}), _style);
var Heading = styled(Text).attrs({
  bold: true
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  font-size: ", ";\n  font-weight: 600;\n  line-height: 1.1;\n\n  ", " {\n    font-size: ", ";\n  }\n"])), function (_ref) {
  var scale = _ref.scale;
  return style[scale || scales.MD].fontSize;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.mediaQueries.lg;
}, function (_ref3) {
  var scale = _ref3.scale;
  return style[scale || scales.MD].fontSizeLg;
});
Heading.defaultProps = {
  as: tags.H2
};
export default Heading;