import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from "styled-components";
import { scales } from "./types";

var getScale = function getScale(_ref) {
  var scale = _ref.scale;

  switch (scale) {
    case scales.SM:
      return "24px";

    case scales.MD:
    default:
      return "32px";
  }
};

var Checkbox = styled.input.attrs({
  type: "checkbox"
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  appearance: none;\n  overflow: hidden;\n  cursor: pointer;\n  position: relative;\n  display: inline-block;\n  height: ", ";\n  width: ", ";\n  vertical-align: middle;\n  transition: background-color 0.2s ease-in-out;\n  border: 0;\n  border-radius: 8px;\n  background-color: ", ";\n  box-shadow: ", ";\n\n  &:after {\n    content: \"\";\n    position: absolute;\n    border-bottom: 2px solid;\n    border-left: 2px solid;\n    border-color: transparent;\n    top: 30%;\n    left: 0;\n    right: 0;\n    width: 50%;\n    height: 25%;\n    margin: auto;\n    transform: rotate(-50deg);\n    transition: border-color 0.2s ease-in-out;\n  }\n\n  &:hover:not(:disabled):not(:checked) {\n    box-shadow: ", ";\n  }\n\n  &:focus {\n    outline: none;\n    box-shadow: ", ";\n  }\n\n  &:checked {\n    background-color: ", ";\n    &:after {\n      border-color: white;\n    }\n  }\n\n  &:disabled {\n    cursor: default;\n    opacity: 0.6;\n  }\n"])), getScale, getScale, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.input;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.shadows.inset;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.shadows.focus;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.shadows.focus;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.colors.success;
});
Checkbox.defaultProps = {
  scale: scales.MD
};
export default Checkbox;