import { serializeTokens } from 'utils/serializeTokens'
import { bscTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens(bscTokens)

export const CAKE_BNB_LP_MAINNET = '0x717Ef9CF2cB13e414Fa567e6070a7737E0CF7C17'

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'DEP',
    lpAddresses: {
      97: '0x03cF012A580117d40a748d84aDa53758A45a5033',
      11451: '0x30885515b9AeCc599Dc6D48106B471EAd26dEBB0',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.usdt,
    isTokenOnly: true,
  },
  {
    pid: 1,
    lpSymbol: 'DEP-USDT LP',
    lpAddresses: {
      97: '0x03cF012A580117d40a748d84aDa53758A45a5033',
      11451: '0x30885515b9AeCc599Dc6D48106B471EAd26dEBB0',
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.usdt,
  },
  {
    pid: 2,
    lpSymbol: 'DEP-XAU LP',
    lpAddresses: {
      97: '0x3ed8936cAFDF85cfDBa29Fbe5940A5b0524824F4',
      11451: CAKE_BNB_LP_MAINNET,
    },
    token: serializedTokens.cake,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 3,
    lpSymbol: 'USDT-XAU LP',
    lpAddresses: {
      97: '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
      11451: '0xA8f8B7C0a4ec1ca9fA115dAe915e33AEDdf2526B',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 5,
    lpSymbol: 'WCLD',
    lpAddresses: {
      97: '0x03cF012A580117d40a748d84aDa53758A45a5033',
      11451: '0xA8f8B7C0a4ec1ca9fA115dAe915e33AEDdf2526B',
    },
    token: serializedTokens.wbnb,
    quoteToken: serializedTokens.usdt,
    isTokenOnly: true,
  },
]

export default farms
