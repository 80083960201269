import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import styled from "styled-components";
import { typography } from "styled-system";
export var Td = styled.td(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border-bottom: 1px solid ", ";\n  color: ", ";\n  padding: 16px;\n  vertical-align: middle;\n\n  ", "\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.cardBorder;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.text;
}, typography);
export var Th = styled(Td).attrs({
  as: "th"
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: 12px;\n  text-transform: uppercase;\n"])), function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.secondary;
});