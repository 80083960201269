import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

import styled from "styled-components";
import { Text } from "../Text";

var getTextColor = function getTextColor(_ref) {
  var $isActive = _ref.$isActive,
      disabled = _ref.disabled,
      theme = _ref.theme;
  if (disabled) return theme.colors.textDisabled;
  if ($isActive) return theme.colors.secondary;
  return theme.colors.textSubtle;
};

export var DropdownMenuItem = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  align-items: center;\n  border: 0;\n  background: transparent;\n  color: ", ";\n  cursor: ", ";\n  font-weight: ", ";\n  display: flex;\n  font-size: 16px;\n  height: 48px;\n  justify-content: space-between;\n  outline: 0;\n  padding-left: 16px;\n  padding-right: 16px;\n  width: 100%;\n\n  &:hover:not(:disabled) {\n    background-color: ", ";\n  }\n\n  &:active:not(:disabled) {\n    opacity: 0.85;\n    transform: translateY(1px);\n  }\n"])), function (_ref2) {
  var theme = _ref2.theme,
      disabled = _ref2.disabled,
      $isActive = _ref2.$isActive;
  return getTextColor({
    theme: theme,
    disabled: disabled,
    $isActive: $isActive
  });
}, function (_ref3) {
  var disabled = _ref3.disabled;
  return disabled ? "not-allowed" : "pointer";
}, function (_ref4) {
  var _ref4$$isActive = _ref4.$isActive,
      $isActive = _ref4$$isActive === void 0 ? false : _ref4$$isActive;
  return $isActive ? "600" : "400";
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.tertiary;
});
export var StyledDropdownMenuItemContainer = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  &:first-child > ", " {\n    border-top-left-radius: 8px;\n    border-top-right-radius: 8px;\n  }\n\n  &:last-child > ", " {\n    border-bottom-left-radius: 8px;\n    border-bottom-right-radius: 8px;\n  }\n"])), DropdownMenuItem, DropdownMenuItem);
export var DropdownMenuDivider = styled.hr(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  border-color: ", ";\n  border-style: solid;\n  border-width: 1px 0 0;\n  margin: 4px 0;\n"])), function (_ref6) {
  var theme = _ref6.theme;
  return theme.colors.cardBorder;
});
export var StyledDropdownMenu = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: 16px;\n  padding-bottom: 4px;\n  padding-top: 4px;\n  pointer-events: auto;\n  width: ", ";\n  visibility: visible;\n  z-index: 1001;\n\n  ", "\n"])), function (_ref7) {
  var theme = _ref7.theme;
  return theme.card.background;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.colors.cardBorder;
}, function (_ref9) {
  var $isBottomNav = _ref9.$isBottomNav;
  return $isBottomNav ? "calc(100% - 32px)" : "280px";
}, function (_ref10) {
  var $isOpen = _ref10.$isOpen;
  return !$isOpen && "\n    pointer-events: none;\n    visibility: hidden;\n  ";
});
export var LinkStatus = styled(Text)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  border-radius: ", ";\n  padding: 0 8px;\n  border: 2px solid;\n  border-color: ", ";\n  box-shadow: none;\n  color: ", ";\n  margin-left: 8px;\n"])), function (_ref11) {
  var theme = _ref11.theme;
  return theme.radii.default;
}, function (_ref12) {
  var theme = _ref12.theme,
      color = _ref12.color;
  return theme.colors[color];
}, function (_ref13) {
  var theme = _ref13.theme,
      color = _ref13.color;
  return theme.colors[color];
});