import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from "styled-components";
import Button from "./Button";
var IconButton = styled(Button)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding: 0;\n  width: ", ";\n"])), function (_ref) {
  var scale = _ref.scale;
  return scale === "sm" ? "32px" : "48px";
});
export default IconButton;