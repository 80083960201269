export const BSC_RPC_URLS = [
  'https://rpc.egoldchain.com',
  'https://rpc.egoldchain.com',
  'https://rpc.egoldchain.com',
]

export const BSC_TESTNET_RPC_URLS = [
  'https://data-seed-prebsc-1-s1.cloudtx.finance:8545',
  'https://data-seed-prebsc-2-s1.cloudtx.finance:8545',
  'https://data-seed-prebsc-1-s3.cloudtx.finance:8545',
]
