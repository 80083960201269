import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3;

import styled from "styled-components";
import { scales } from "./types";
var scaleKeyValues = {
  sm: {
    handleHeight: "16px",
    handleWidth: "16px",
    handleLeft: "2px",
    handleTop: "2px",
    checkedLeft: "calc(100% - 18px)",
    toggleHeight: "20px",
    toggleWidth: "36px"
  },
  md: {
    handleHeight: "26px",
    handleWidth: "26px",
    handleLeft: "3px",
    handleTop: "3px",
    checkedLeft: "calc(100% - 30px)",
    toggleHeight: "32px",
    toggleWidth: "56px"
  },
  lg: {
    handleHeight: "32px",
    handleWidth: "32px",
    handleLeft: "4px",
    handleTop: "4px",
    checkedLeft: "calc(100% - 36px)",
    toggleHeight: "40px",
    toggleWidth: "72px"
  }
};

var getScale = function getScale(property) {
  return function (_ref) {
    var _ref$scale = _ref.scale,
        scale = _ref$scale === void 0 ? scales.LG : _ref$scale;
    return scaleKeyValues[scale][property];
  };
};

export var Handle = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: ", ";\n  border-radius: 50%;\n  cursor: pointer;\n  height: ", ";\n  left: ", ";\n  position: absolute;\n  top: ", ";\n  transition: left 200ms ease-in;\n  width: ", ";\n  z-index: 1;\n"])), function (_ref2) {
  var theme = _ref2.theme;
  return theme.toggle.handleBackground;
}, getScale("handleHeight"), getScale("handleLeft"), getScale("handleTop"), getScale("handleWidth"));
export var Input = styled.input(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  cursor: pointer;\n  opacity: 0;\n  height: 100%;\n  position: absolute;\n  width: 100%;\n  z-index: 3;\n\n  &:checked + ", " {\n    left: ", ";\n  }\n\n  &:focus + ", " {\n    box-shadow: ", ";\n  }\n\n  &:hover + ", ":not(:disabled):not(:checked) {\n    box-shadow: ", ";\n  }\n"])), Handle, getScale("checkedLeft"), Handle, function (_ref3) {
  var theme = _ref3.theme;
  return theme.shadows.focus;
}, Handle, function (_ref4) {
  var theme = _ref4.theme;
  return theme.shadows.focus;
});
var StyledToggle = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  align-items: center;\n  background-color: ", ";\n  border-radius: 24px;\n  box-shadow: ", ";\n  cursor: pointer;\n  display: inline-flex;\n  height: ", ";\n  position: relative;\n  transition: background-color 200ms;\n  width: ", ";\n"])), function (_ref5) {
  var theme = _ref5.theme,
      $checked = _ref5.$checked,
      $checkedColor = _ref5.$checkedColor,
      $defaultColor = _ref5.$defaultColor;
  return theme.colors[$checked ? $checkedColor : $defaultColor];
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.shadows.inset;
}, getScale("toggleHeight"), getScale("toggleWidth"));
export default StyledToggle;