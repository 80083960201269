export var scales = {
  SM: "sm",
  MD: "md",
  LG: "lg"
};
export var scaleKeys = {
  pancakeSize: "pancakeSize",
  travelDistance: "travelDistance",
  toggleHeight: "toggleHeight",
  toggleWidth: "toggleWidth",
  pancakeThickness: "pancakeThickness",
  pancakeTwoOffset: "pancakeTwoOffset",
  pancakeThreeOffset: "pancakeThreeOffset",
  butterTop: "butterTop",
  butterLeft: "butterLeft",
  butterWidth: "butterWidth",
  butterHeight: "butterHeight",
  butterThickness: "butterThickness",
  butterRadius: "butterRadius",
  butterSmearOneTop: "butterSmearOneTop",
  butterSmearOneLeft: "butterSmearOneLeft",
  butterSmearTwoTop: "butterSmearTwoTop",
  butterSmearTwoRight: "butterSmearTwoRight"
};