import { useEffect } from "react";

var useKonamiCheatCode = function useKonamiCheatCode(matchedCodeHandler) {
  useEffect(function () {
    var pattern = ["ArrowUp", "ArrowUp", "ArrowDown", "ArrowDown", "ArrowLeft", "ArrowRight", "ArrowLeft", "ArrowRight"];
    var currentIndex = 0;

    var onKeyUpHandler = function onKeyUpHandler(event) {
      var key = event.key; // is key in correct order otherwise reset

      if (key !== pattern[currentIndex]) {
        currentIndex = 0;
        return;
      }

      currentIndex += 1;

      if (pattern.length === currentIndex) {
        currentIndex = 0;
        matchedCodeHandler();
      }
    };

    document.addEventListener("keyup", onKeyUpHandler);
    return function () {
      return document.removeEventListener("keyup", onKeyUpHandler);
    };
  }, [matchedCodeHandler]);
};

export default useKonamiCheatCode;