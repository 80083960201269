import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from "styled-components";
import { Flex } from "../Box";
var StyledBottomNav = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: fixed;\n  bottom: 0px;\n  width: 100%;\n  padding: 5px 8px;\n  background: ", ";\n  border-top: 1px solid ", ";\n  padding-bottom: env(safe-area-inset-bottom);\n  html[data-useragent*=\"TokenPocket_iOS\"] & {\n    padding-bottom: 45px;\n  }\n  z-index: 20;\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.backgroundAlt;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.cardBorder;
});
export default StyledBottomNav;