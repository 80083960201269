import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from "styled-components";
import { space } from "styled-system";
var CardHeader = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background: ", ";\n  border-radius: ", ";\n  ", "\n"])), function (_ref) {
  var theme = _ref.theme,
      _ref$variant = _ref.variant,
      variant = _ref$variant === void 0 ? "default" : _ref$variant;
  return theme.card.cardHeaderBackground[variant];
}, function (_ref2) {
  var theme = _ref2.theme;
  return "".concat(theme.radii.card, " ").concat(theme.radii.card, " 0 0");
}, space);
CardHeader.defaultProps = {
  p: "24px"
};
export default CardHeader;