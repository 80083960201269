import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

/* eslint-disable import/prefer-default-export */
import styled, { keyframes, css } from "styled-components";
export var mountAnimation = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    0% {\n      transform: translateY(20%);\n    }\n    100% {\n      transform: translateY(0%);\n    }\n  "])));
export var unmountAnimation = keyframes(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    0% {\n      transform: translateY(0%);\n    }\n    100% {\n      transform: translateY(20%);\n    }\n  "])));
export var DrawerContainer = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 100%;\n  height: 80vh;\n  bottom: 0;\n  background-color: ", ";\n  border-top-left-radius: 32px;\n  border-top-right-radius: 32px;\n  position: fixed;\n  animation: ", " 350ms ease forwards;\n  padding-bottom: env(safe-area-inset-bottom);\n  html[data-useragent*=\"TokenPocket_iOS\"] & {\n    padding-bottom: 45px;\n  }\n  will-change: transform;\n  z-index: 21;\n  ", "\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.backgroundAlt;
}, mountAnimation, function (_ref2) {
  var isUnmounting = _ref2.isUnmounting;
  return isUnmounting && css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n      animation: ", " 350ms ease forwards;\n    "])), unmountAnimation);
});