import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from "styled-components";
import Grid from "../Box/Grid";
var GridLayout = styled(Grid)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  grid-template-columns: repeat(6, 1fr);\n  grid-gap: 16px;\n  ", " {\n    grid-template-columns: repeat(8, 1fr);\n    grid-gap: 24px;\n  }\n  ", " {\n    grid-template-columns: repeat(12, 1fr);\n    grid-gap: 24px;\n  }\n  ", " {\n    grid-template-columns: repeat(12, 1fr);\n    grid-gap: 32px;\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.mediaQueries.sm;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.mediaQueries.md;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.mediaQueries.lg;
});
export default GridLayout;