import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from "styled-components";
import Text from "./Text";
var TooltipText = styled(Text)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  text-decoration: ", ";\n  text-underline-offset: 0.1em;\n"])), function (_ref) {
  var theme = _ref.theme;
  return "underline dotted ".concat(theme.colors.textSubtle);
});
export default TooltipText;