import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import styled from "styled-components";
export var StyledIconContainer = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background: ", ";\n"])), function (_ref) {
  var activeBackgroundColor = _ref.activeBackgroundColor,
      theme = _ref.theme;
  return activeBackgroundColor ? theme.colors[activeBackgroundColor] : "transparent";
});
export var StyledAnimatedIconComponent = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: relative;\n  ", ";\n  ", ";\n\n  div:first-child {\n    ", ";\n    ", ";\n    z-index: 0;\n  }\n  ", "\n\n  ", "\n"])), function (_ref2) {
  var height = _ref2.height;
  return height && "height: ".concat(height);
}, function (_ref3) {
  var width = _ref3.width;
  return "width: ".concat(width || "100%");
}, function (_ref4) {
  var height = _ref4.height;
  return height && "height: ".concat(height);
}, function (_ref5) {
  var width = _ref5.width;
  return "width: ".concat(width || "100%");
}, function (_ref6) {
  var hasFillIcon = _ref6.hasFillIcon;
  return hasFillIcon && "\n    div, svg {\n      position: absolute;\n      left: 0;\n      bottom: 0;\n      overflow:hidden;\n    }\n\n    div:last-child {\n      height: 0;\n      z-index: 5;\n      transition: height 0.25s ease;\n    }\n  ";
}, function (_ref7) {
  var isActive = _ref7.isActive,
      height = _ref7.height,
      width = _ref7.width,
      hasFillIcon = _ref7.hasFillIcon;
  return isActive && "\n    div:last-child {\n      ".concat(height && hasFillIcon && "height:".concat(height), ";\n      ", "width: ".concat(width || "100%"), ";\n    }\n  ");
});