import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import styled, { css } from "styled-components";
import { Flex, Box } from "../Box";
export var SubMenuItemWrapper = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", " {\n    ", ";\n  }\n  width: 100%;\n  overflow: hidden;\n  position: relative;\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.mediaQueries.sm;
}, function (_ref2) {
  var $isMobileOnly = _ref2.$isMobileOnly;
  return $isMobileOnly ? "display:none" : "";
});
var StyledSubMenuItems = styled(Flex)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: relative;\n  z-index: 1;\n  width: 100%;\n  display: block;\n  white-space: nowrap;\n  scroll-behavior: smooth;\n  ", " {\n    width: auto;\n    display: flex;\n  }\n  flex-grow: 1;\n  background-color: ", ";\n  box-shadow: inset 0px -2px 0px -8px rgba(133, 133, 133, 0.1);\n  overflow-x: scroll;\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n  &::-webkit-scrollbar {\n    display: none;\n  }\n"])), function (_ref3) {
  var theme = _ref3.theme;
  return theme.mediaQueries.md;
}, function (_ref4) {
  var theme = _ref4.theme;
  return "".concat(theme.colors.backgroundAlt2);
});
var maskSharedStyle = css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  position: absolute;\n  z-index: 2;\n  width: 48px;\n  height: 100%;\n  top: 0px;\n  display: flex;\n  justify-content: center;\n  opacity: 1;\n  will-change: opacity;\n  transition: 0.25s ease-in opacity;\n  &.hide {\n    pointer-events: none;\n    opacity: 0;\n    transition: 0.25s ease-out opacity;\n  }\n"])));
export var LeftMaskLayer = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n  left: 0px;\n  background: ", ";\n"])), maskSharedStyle, function (_ref5) {
  var theme = _ref5.theme;
  return theme.isDark ? "linear-gradient(90deg, #202639 29.76%, rgba(39,38,44, 0) 100%)" : "linear-gradient(90deg, #ffffff 29.76%, rgba(255, 255, 255, 0) 100%)";
});
export var RightMaskLayer = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n  right: 0px;\n  background: ", ";\n"])), maskSharedStyle, function (_ref6) {
  var theme = _ref6.theme;
  return theme.isDark ? "linear-gradient(270deg, #27262c 0%, rgba(39,38,44, 0) 87.5%)" : "linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 87.5%)";
});
export var StyledSubMenuItemWrapper = styled(Box)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: inline-block;\n  vertical-align: top;\n  scroll-snap-align: start;\n"])));
export default StyledSubMenuItems;