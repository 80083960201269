export var byTextAscending = function byTextAscending(getTextProperty) {
  return function (objectA, objectB) {
    var upperA = getTextProperty(objectA).toUpperCase();
    var upperB = getTextProperty(objectB).toUpperCase();

    if (upperA < upperB) {
      return -1;
    }

    if (upperA > upperB) {
      return 1;
    }

    return 0;
  };
};
export var byTextDescending = function byTextDescending(getTextProperty) {
  return function (objectA, objectB) {
    var upperA = getTextProperty(objectA).toUpperCase();
    var upperB = getTextProperty(objectB).toUpperCase();

    if (upperA > upperB) {
      return -1;
    }

    if (upperA < upperB) {
      return 1;
    }

    return 0;
  };
};