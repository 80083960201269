import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import styled from "styled-components";
import { space, variant as StyledSystemVariant } from "styled-system";
import { lightColors } from "../../theme";
import { styleVariants, styleScales } from "./themes";
import { variants } from "./types";
export var Bar = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  background: ", ";\n  height: 100%;\n  transition: width 200ms ease;\n"])), function (_ref) {
  var theme = _ref.theme,
      $useDark = _ref.$useDark,
      primary = _ref.primary,
      $background = _ref.$background;
  if ($background) return $background;
  if ($useDark) return primary ? theme.colors.secondary : "".concat(theme.colors.secondary, "80");
  return primary ? lightColors.secondary : "".concat(lightColors.secondary, "80");
});
Bar.defaultProps = {
  primary: false
};
var StyledProgress = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: relative;\n  background-color: ", ";\n  box-shadow: ", ";\n  overflow: hidden;\n\n  ", " {\n    border-top-left-radius: ", ";\n    border-bottom-left-radius: ", ";\n  }\n\n  ", "\n  ", "\n  ", "\n"])), function (_ref2) {
  var theme = _ref2.theme,
      $useDark = _ref2.$useDark;
  return $useDark ? theme.colors.input : lightColors.input;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.shadows.inset;
}, Bar, function (_ref4) {
  var variant = _ref4.variant;
  return variant === variants.FLAT ? "0" : "32px";
}, function (_ref5) {
  var variant = _ref5.variant;
  return variant === variants.FLAT ? "0" : "32px";
}, StyledSystemVariant({
  variants: styleVariants
}), StyledSystemVariant({
  prop: "scale",
  variants: styleScales
}), space);
export default StyledProgress;