import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import styled from "styled-components";
export var UserMenuDivider = styled.hr(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border-color: ", ";\n  border-style: solid;\n  border-width: 1px 0 0;\n  margin: 4px 0;\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.cardBorder;
});
export var UserMenuItem = styled.button(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  align-items: center;\n  border: 0;\n  background: transparent;\n  color: ", ";\n  cursor: ", ";\n  display: flex;\n  font-size: 16px;\n  height: 48px;\n  justify-content: space-between;\n  outline: 0;\n  padding-left: 16px;\n  padding-right: 16px;\n  width: 100%;\n\n  &:hover:not(:disabled) {\n    background-color: ", ";\n  }\n\n  &:active:not(:disabled) {\n    opacity: 0.85;\n    transform: translateY(1px);\n  }\n"])), function (_ref2) {
  var theme = _ref2.theme,
      disabled = _ref2.disabled;
  return theme.colors[disabled ? "textDisabled" : "textSubtle"];
}, function (_ref3) {
  var disabled = _ref3.disabled;
  return disabled ? "not-allowed" : "pointer";
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.tertiary;
});