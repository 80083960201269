import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import styled from "styled-components";
import { Box, Flex } from "../Box";
export var InlineMenuContainer = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: ", ";\n  border-radius: ", ";\n  box-shadow: ", ";\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.backgroundAlt;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.radii.card;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.shadows.tooltip;
});
export var SubMenuContainer = styled(Flex)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex-direction: column;\n  overflow: hidden;\n  min-width: 136px;\n  background: ", ";\n  border-radius: ", ";\n  border: ", ";\n"])), function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.input;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.radii.default;
}, function (_ref6) {
  var theme = _ref6.theme;
  return "1px solid ".concat(theme.colors.inputSecondary);
});
export var ClickableElementContainer = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  cursor: pointer;\n  display: inline-flex;\n"])));
export var SubMenuItem = styled.button(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  border: 0;\n  outline: 0;\n  cursor: pointer;\n  background: transparent;\n  padding: 8px 16px;\n  color: ", ";\n  width: 100%;\n  font-size: 16px;\n  text-align: left;\n\n  &:hover {\n    background-color: ", ";\n    text-decoration: none;\n  }\n"])), function (_ref7) {
  var theme = _ref7.theme;
  return theme.colors.text;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.colors.inputSecondary;
});