import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useState } from "react";
import { breakpointMap } from "../theme/base";
import { useIsomorphicEffect } from "./useIsomorphicEffect";

/**
 * Can't use the media queries from "base.mediaQueries" because of how matchMedia works
 * In order for the listener to trigger we need have the media query with a range, e.g.
 * (min-width: 370px) and (max-width: 576px)
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList
 */
var mediaQueries = function () {
  var prevMinWidth = 0;
  return Object.keys(breakpointMap).reduce(function (accum, size, index) {
    // Largest size is just a min-width of second highest max-width
    if (index === Object.keys(breakpointMap).length - 1) {
      return _objectSpread(_objectSpread({}, accum), {}, _defineProperty({}, size, "(min-width: ".concat(prevMinWidth, "px)")));
    }

    var minWidth = prevMinWidth;
    var breakpoint = breakpointMap[size]; // Min width for next iteration

    prevMinWidth = breakpoint;
    return _objectSpread(_objectSpread({}, accum), {}, _defineProperty({}, size, "(min-width: ".concat(minWidth, "px) and (max-width: ").concat(breakpoint - 1, "px)")));
  }, {});
}();

var getKey = function getKey(size) {
  return "is".concat(size.charAt(0).toUpperCase()).concat(size.slice(1));
};

var getState = function getState() {
  var s = Object.keys(mediaQueries).reduce(function (accum, size) {
    var _window, _mql$matches;

    var key = getKey(size);

    if (false) {
      return _objectSpread(_objectSpread({}, accum), {}, _defineProperty({}, key, false));
    }

    var mql = typeof ((_window = window) === null || _window === void 0 ? void 0 : _window.matchMedia) === "function" ? window.matchMedia(mediaQueries[size]) : null;
    return _objectSpread(_objectSpread({}, accum), {}, _defineProperty({}, key, (_mql$matches = mql === null || mql === void 0 ? void 0 : mql.matches) !== null && _mql$matches !== void 0 ? _mql$matches : false));
  }, {});
  return s;
};

var useMatchBreakpoints = function useMatchBreakpoints() {
  var _useState = useState(function () {
    return getState();
  }),
      state = _useState[0],
      setState = _useState[1];

  useIsomorphicEffect(function () {
    // Create listeners for each media query returning a function to unsubscribe
    var handlers = Object.keys(mediaQueries).map(function (size) {
      var _window2;

      var mql;
      var handler;

      if (typeof ((_window2 = window) === null || _window2 === void 0 ? void 0 : _window2.matchMedia) === "function") {
        mql = window.matchMedia(mediaQueries[size]);

        handler = function handler(matchMediaQuery) {
          var key = getKey(size);
          setState(function (prevState) {
            return _objectSpread(_objectSpread({}, prevState), {}, _defineProperty({}, key, matchMediaQuery.matches));
          });
        }; // Safari < 14 fix


        if (mql.addEventListener) {
          mql.addEventListener("change", handler);
        }
      }

      return function () {
        var _mql;

        // Safari < 14 fix
        if ((_mql = mql) !== null && _mql !== void 0 && _mql.removeEventListener) {
          mql.removeEventListener("change", handler);
        }
      };
    });
    setState(getState());
    return function () {
      handlers.forEach(function (unsubscribe) {
        unsubscribe();
      });
    };
  }, []);
  return _objectSpread(_objectSpread({}, state), {}, {
    isMobile: state.isXs || state.isSm,
    isTablet: state.isMd || state.isLg,
    isDesktop: state.isXl || state.isXxl
  });
};

export default useMatchBreakpoints;