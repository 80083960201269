import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from "styled-components";
import { space, variant, typography } from "styled-system";
import { scaleVariants, styleVariants } from "./theme";
import { variants } from "./types";

var getOutlineStyles = function getOutlineStyles(_ref) {
  var outline = _ref.outline,
      theme = _ref.theme,
      _ref$variant = _ref.variant,
      variantKey = _ref$variant === void 0 ? variants.PRIMARY : _ref$variant;

  if (outline) {
    var themeColorKey = styleVariants[variantKey].backgroundColor;
    var color = theme.colors[themeColorKey];
    return "\n      color: ".concat(color, ";\n      background: ").concat(theme.colors.background, ";\n      border: 2px solid ").concat(color, ";\n    ");
  }

  return "";
};

export var StyledTag = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  align-items: center;\n  border-radius: 16px;\n  color: #ffffff;\n  display: inline-flex;\n  font-weight: 400;\n  white-space: nowrap;\n\n  & > svg {\n    fill: currentColor;\n  }\n\n  ", "\n\n  ", "\n  ", "\n  ", "\n  ", "\n\n  ", "\n"])), function (_ref2) {
  var textTransform = _ref2.textTransform;
  return textTransform && "text-transform: ".concat(textTransform, ";");
}, variant({
  prop: "scale",
  variants: scaleVariants
}), variant({
  variants: styleVariants
}), space, typography, getOutlineStyles);
export default null;