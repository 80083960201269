import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import styled from "styled-components";
import Box from "../Box/Box";
import Input from "../Input/Input";
import Text from "../Text/Text";
import IconButton from "../Button/IconButton";
export var SwitchUnitsButton = styled(IconButton)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 16px;\n"])));
export var UnitContainer = styled(Text)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin-left: 4px;\n  text-align: right;\n  color: ", ";\n  white-space: nowrap;\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.textSubtle;
});
export var StyledBalanceInput = styled(Box)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-radius: 16px;\n  box-shadow: ", ";\n  padding: 8px 16px;\n"])), function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.input;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.inputSecondary;
}, function (_ref4) {
  var theme = _ref4.theme,
      isWarning = _ref4.isWarning;
  return theme.shadows[isWarning ? "warning" : "inset"];
});
export var StyledInput = styled(Input)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  background: transparent;\n  border-radius: 0;\n  box-shadow: none;\n  padding-left: 0;\n  padding-right: 0;\n  text-align: ", ";\n  border: none;\n\n  ::placeholder {\n    color: ", ";\n  }\n\n  &:focus:not(:disabled) {\n    box-shadow: none;\n  }\n"])), function (_ref5) {
  var _ref5$textAlign = _ref5.textAlign,
      textAlign = _ref5$textAlign === void 0 ? "right" : _ref5$textAlign;
  return textAlign;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.colors.textSubtle;
});