import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2;

import styled from "styled-components";
import { m as Motion } from "framer-motion";
export var Arrow = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  &,\n  &::before {\n    position: absolute;\n    width: 10px;\n    height: 10px;\n    border-radius: 2px;\n    z-index: -1;\n  }\n\n  &::before {\n    content: \"\";\n    transform: rotate(45deg);\n    background: ", ";\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.tooltip.background;
});
export var StyledTooltip = styled(Motion.div)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding: 16px;\n  font-size: 16px;\n  line-height: 130%;\n  border-radius: 16px;\n  max-width: 320px;\n  z-index: 101;\n  background: ", ";\n  color: ", ";\n  box-shadow: ", ";\n\n  &[data-popper-placement^=\"top\"] > ", " {\n    bottom: -4px;\n  }\n\n  &[data-popper-placement^=\"bottom\"] > ", " {\n    top: -4px;\n  }\n\n  &[data-popper-placement^=\"left\"] > ", " {\n    right: -4px;\n  }\n\n  &[data-popper-placement^=\"right\"] > ", " {\n    left: -4px;\n  }\n"])), function (_ref2) {
  var theme = _ref2.theme;
  return theme.tooltip.background;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.tooltip.text;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.tooltip.boxShadow;
}, Arrow, Arrow, Arrow, Arrow);