import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from "styled-components";
import BaseLayout from "./BaseLayout";
var GridLayout = styled(BaseLayout)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  & > div {\n    grid-column: span 6;\n    ", " {\n      grid-column: span 4;\n    }\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.mediaQueries.sm;
});
export default GridLayout;