import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import styled from "styled-components";
import { space } from "styled-system";
import { scales } from "./types";

var getScale = function getScale(_ref) {
  var scale = _ref.scale;

  switch (scale) {
    case scales.SM:
      return "24px";

    case scales.MD:
    default:
      return "32px";
  }
};

var getCheckedScale = function getCheckedScale(_ref2) {
  var scale = _ref2.scale;

  switch (scale) {
    case scales.SM:
      return "12px";

    case scales.MD:
    default:
      return "20px";
  }
};

var Radio = styled.input.attrs({
  type: "radio"
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  appearance: none;\n  overflow: hidden;\n  cursor: pointer;\n  position: relative;\n  display: inline-block;\n  height: ", ";\n  width: ", ";\n  vertical-align: middle;\n  transition: background-color 0.2s ease-in-out;\n  border: 0;\n  border-radius: 50%;\n  background-color: ", ";\n  box-shadow: ", ";\n\n  &:after {\n    border-radius: 50%;\n    content: \"\";\n    height: ", ";\n    left: 6px;\n    position: absolute;\n    top: 6px;\n    width: ", ";\n  }\n\n  &:hover:not(:disabled):not(:checked) {\n    box-shadow: ", ";\n  }\n\n  &:focus {\n    outline: none;\n    box-shadow: ", ";\n  }\n\n  &:checked {\n    background-color: ", ";\n    &:after {\n      background-color: ", ";\n    }\n  }\n\n  &:disabled {\n    cursor: default;\n    opacity: 0.6;\n  }\n  ", "\n"])), getScale, getScale, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.input;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.shadows.inset;
}, getCheckedScale, getCheckedScale, function (_ref5) {
  var theme = _ref5.theme;
  return theme.shadows.focus;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.shadows.focus;
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.colors.success;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.radio.handleBackground;
}, space);
Radio.defaultProps = {
  scale: scales.MD,
  m: 0
};
export default Radio;