import _taggedTemplateLiteral from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

import styled from "styled-components";
import { darkColors } from "../../theme/colors";
import { Box, Flex } from "../Box";
import SocialLinks from "./Components/SocialLinks";
export var StyledFooter = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background: ", ";\n"])), darkColors.backgroundAlt);
export var StyledList = styled.ul(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  list-style: none;\n  margin-bottom: 40px;\n\n  ", " {\n    margin-bottom: 0px;\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.mediaQueries.md;
});
export var StyledListItem = styled.li(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-size: 16px;\n  margin-bottom: 8px;\n  text-transform: capitalize;\n\n  &:first-child {\n    color: ", ";\n    font-weight: 600;\n    text-transform: uppercase;\n  }\n"])), darkColors.secondary);
export var StyledIconMobileContainer = styled(Box)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  margin-bottom: 24px;\n"])));
export var StyledToolsContainer = styled(Flex)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  border-color: ", ";\n  border-top-width: 1px;\n  border-bottom-width: 1px;\n  border-style: solid;\n  padding: 24px 0;\n  margin-bottom: 24px;\n\n  ", " {\n    border-top-width: 0;\n    border-bottom-width: 0;\n    padding: 0 0;\n    margin-bottom: 0;\n  }\n"])), darkColors.cardBorder, function (_ref2) {
  var theme = _ref2.theme;
  return theme.mediaQueries.sm;
});
export var StyledSocialLinks = styled(SocialLinks)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  border-bottom: 1px solid ", ";\n"])), darkColors.cardBorder);
export var StyledText = styled.span(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  color: ", ";\n"])), darkColors.text);